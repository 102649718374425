import React, { useState } from 'react';
import Submenu from './Submenu';
import { useGlobalFilterContext } from '../../filterContext';
import closeIcon from '../../assets/closeIcon.png';
import filterIcon from '../../assets/filter.svg';
import downArrowIcon from '../../assets/downArrow.svg';

const Searchbar = () => {
	const [isTyping, setIsTyping] = useState(false);
	const [showTypedResult, setShowTypedResult] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const {
		isSubmenuOpen,
		toggleSubmenu,
		updateSort,
		sort,
		filters,
		updateFilters,
		clearFilters,
		filteredPublications,
		closeSubmenu,
		ctr,
	} = useGlobalFilterContext();

	return (
		<section className="search-bar">
			<form action="#" className="form" onSubmit={(e) => e.preventDefault()}>
				{/* FILTER DROPDOWN */}
				<fieldset className="child1">
					<fieldset className="subchild1">
						<fieldset
							type="text"
							name="filter"
							id="filter"
							className="regular-caps filterBx bx"
							value=""
							onClick={() => {
								toggleSubmenu();
								setIsTyping(false);
								setShowTypedResult(false);
							}}>
							<img src={filterIcon} alt="" width='16px' height="16px" />
							{`${ctr > 0 ? `filter (${ctr})` : 'filter'}`}
							<img src={downArrowIcon} alt="" width='12px' height="12px" style={{marginLeft: 'auto'}} />
						</fieldset>
					</fieldset>

					<fieldset className="subchild2">
						<label htmlFor="sort" className="sr-only">
							sort by
						</label>
						

						<select
							name="sort"
							id="sort"
							className="regular-caps sortBx bx "
							value={sort}
							onChange={updateSort}>
							<option
								value="placeholder"
								// selected
								id="sort"
								className="placeholder regular-caps">
								SORT BY
							</option>
							<option value="yearH" className="op regular-caps">
								Newest
							</option>
							<option value="yearL" className="op regular-caps">
								Oldest
							</option>
						</select>
					</fieldset>
					{isSubmenuOpen && <Submenu />}
				</fieldset>
				{/* ---end of sort by dropdown */}

				{/* SEARCH BAR */}
				{/* child  */}
				<fieldset className={`${isTyping ? 'child2 expand-search' : 'child2'}`}>
					<label htmlFor="search" className="sr-only">
						search
					</label>
					<input
						type="text"
						name="text"
						id="search"
						placeholder={`search`}
						value={searchValue}
						className="regular-caps searchBx bx"
						onChange={e => setSearchValue(e.target.value)}
						onClick={() => {
							setIsTyping(true);
							closeSubmenu();
						}}
					/>
					{/* SEARCH button */}
					{isTyping && (
						<button
							className="commonCloseBtn search-button regular-caps"
							type="submit"
							name="text"
							value={searchValue}
							onClick={(e) => {
								updateFilters(e);
								setIsTyping(false);
								setShowTypedResult(true);
							}}>
							search
						</button>
					)}

				</fieldset>
				{/* end of search bar dropdrown */}
			</form>

			{/* filtered tags */}
			<div className="filtered-tags-container">
				{/* publication type */}
				{filters.pubType && (
					<fieldset name="pubType">
						<button
							className="filt-btn semi-14"
							name="pubType"
							data-c={0}
							>
							{filters.pubType}
							<img
								src={closeIcon}
								alt="close icon"
								name="pubType"
								className="close-img"
								onClick={(e) => {
									updateFilters(e);
								}}
							/>
						</button>
					</fieldset>
				)}

				{/* author */}
				{filters.authors && (
					<fieldset name="authors">
						<button
							className="filt-btn semi-14"
							name="authors"
							data-c={0}
							>
							{filters.authors}

							<img
								src={closeIcon}
								alt="close icon"
								name="authors"
								className="close-img"
								data-c={0}
								onClick={(e) => {
									updateFilters(e);
								}}
							/>
						</button>
					</fieldset>
				)}

				{/* year */}
				{filters.year && (
					<fieldset name="year">
						<button
							className="filt-btn semi-14"
							name="year"
							data-c={0}>
							{filters.year}

							<img
								src={closeIcon}
								alt="close icon"
								name="year"
								onClick={(e) => {
									updateFilters(e);
								}}
								className="close-img"
								data-c={0}
							/>
						</button>
					</fieldset>
				)}
			</div>

			{/* result text and clear button*/}
			{filteredPublications !== undefined &&
				(filters.pubType || filters.authors || filters.year) &&
				!isTyping &&
				!showTypedResult && (
					<div className="result-found-container">
						<h3 className="result-found">
							{`${
								filteredPublications.length > 1
									? `${filteredPublications.length} results`
									: `${filteredPublications.length} result`
							}`}
						</h3>
						<button
							className="clear-filters-btn regular-caps"
							onClick={(e) => {
								clearFilters(e);
								closeSubmenu();
								setSearchValue('')
							}}>
							clear filters
						</button>
					</div>
				)}

			{/* show typed result */}
			{showTypedResult && (
				<div className="result-found-container">
					<h3 className="result-found">
						{`${
							filteredPublications.length > 1
								? `${filteredPublications.length} results for "${filters.text}"`
								: `${filteredPublications.length} result for "${filters.text}"`
						}`}
					</h3>
					<button
						className="clear-filters-btn regular-caps"
						name="text"
						onClick={(e) => {
							updateFilters(e);
							setIsTyping(false);
							setShowTypedResult(false);
							setSearchValue('')
						}}>
						clear search
					</button>
				</div>
			)}
		</section>
	);
};

export default Searchbar;
