/*
  Utility functions, any generic function that accomplishes an abstract task.
*/
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

export const printMonthYear = (dateString) => {
	const date = new Date(dateString);
	const dateArray = date.toString().split(' ');
	return `${dateArray[1]} ${dateArray[3]}`;
};

export const getUniqueValues = (data, type) => {
	let unique = data.map((item) => item[type]);
	if (type === 'date') {
		unique = data.map((item) => item[type].split('-')[0]);
	}
	if (type === 'authors') {
		unique = data.map((item) => {
			return item?.authors?.map((subItem) => `${subItem?.fields?.name} ${subItem?.fields?.lastName}`);
		});
		unique = unique.flat();
	}
	if (type === 'publicationType') {
		unique = data.map((item) => {
			const targetString = item.publicationType;
			const arr = targetString.split(' ');

			for (let i = 0; i < arr.length; i++) {
				arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
			}
			const str2 = arr.join(' ');
			return str2;
		});
	}
	return [
		...new Set(
			unique.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'accent' }))
		),
	];
};

export const dropdownRelatedData = (uniqueItems) => {
	const tempData = [];
	uniqueItems.reduce(
		(acc, curr) => {
			acc.value = curr;
			acc.label = curr;
			tempData.push({ value: acc.value, label: acc.label });

			return acc;
		},
		{ value: '', label: '' }
	);

	return tempData;
};

export const options = {
	renderMark: {
		[MARKS.BOLD]: (text) => <strong>{text}</strong>,
		[MARKS.ITALIC]: (text) => <em>{text}</em>,
		[MARKS.UNDERLINE]: (text) => <u>{text}</u>,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => (
			<div className="paragraph">{children}</div>
		),
		[BLOCKS.UL_LIST]: (node, children) => (
			<ul className="contentful-ul">{children}</ul>
		),
		[BLOCKS.OL_LIST]: (node, children) => (
			<ol className="contentful-ol">{children}</ol>
		),
		[BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
		[INLINES.HYPERLINK]: (node, children) => {
			const uri = node.data.uri;
			return (
				<a href={uri} target="_blank" rel="noopener noreferrer">
					{children}
				</a>
			);
		},
	},
};

export const groups = [
	{ id: 'directors', name: 'Directors' },
	{ id: 'staff', name: 'Staff' },
	{ id: 'postdoctoral-fellows', name: 'Postdoctoral Fellows' },
	{ id: 'students', name: 'Students' },
	{ id: 'faculty-affiliates', name: 'Faculty Affiliates' },
	{ id: 'community-partners', name: 'Community Partners' },
];
