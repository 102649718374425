import React from 'react';
import defaultImg from '../../assets/defaultImg.jpg';
import { Link } from 'react-router-dom';

const TeamMember = ({ memberInfo }) => {
	if (!memberInfo) return null;
	const { slug, group, name, lastName, affiliateInformation, img } =
		memberInfo;
	const personImg = img?.fields?.file?.url || defaultImg;
	const profileData = { ...memberInfo, personImg };

	return (
		<li className="tm-card" key={slug}>
			<Link
				to={`/people/${group}/${slug}`}
				state={profileData}
				className="profile-link">
				{/* person image */}
				<div className={`${personImg ? 'profile-img' : 'default-img'}`}>
					<img
						src={personImg}
						alt={personImg ? `${name}is looking at the camera` : ''}
					/>
				</div>

				{/* person name */}
				<div className="profile-content">
					<h2 className="semi-18 profile-name">
						{name} {lastName || ''}
					</h2>

					{/* person title */}
					{affiliateInformation && affiliateInformation[0]?.fields && (
						<>
							<h2 className="title medium-14" id="target">
								{affiliateInformation[0]?.fields?.title}
							</h2>
							<h2 className="title medium-14" id="target">
								{affiliateInformation[0]?.fields?.institution}
							</h2>
						</>
					)}
				</div>
			</Link>
		</li>
	);
};

export default TeamMember;
